exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-dice-part-one-dice-part-one-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/dice_part_one/dice_part_one.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-dice-part-one-dice-part-one-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-dice-part-two-dice-part-two-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/dice_part_two/dice_part_two.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-dice-part-two-dice-part-two-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-dynamic-visualizations-dynamic-visualizations-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/dynamic_visualizations/dynamic_visualizations.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-dynamic-visualizations-dynamic-visualizations-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-escher-and-tessellations-escher-and-tessellations-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/escher_and_tessellations/escher_and_tessellations.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-escher-and-tessellations-escher-and-tessellations-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-first-post-first-post-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/first_post/first_post.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-first-post-first-post-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-guitar-tuning-part-one-guitar-tuning-part-one-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/guitar_tuning_part_one/guitar_tuning_part_one.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-guitar-tuning-part-one-guitar-tuning-part-one-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-guitar-tuning-part-two-guitar-tuning-part-two-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/guitar_tuning_part_two/guitar_tuning_part_two.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-guitar-tuning-part-two-guitar-tuning-part-two-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-leonardo-birthday-leonardo-birthday-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/leonardo_birthday/leonardo_birthday.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-leonardo-birthday-leonardo-birthday-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-posts-pictures-of-chaos-pictures-of-chaos-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/data/posts/pictures_of_chaos/pictures_of_chaos.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-posts-pictures-of-chaos-pictures-of-chaos-mdx" */)
}

